<template>
        <div class="row">
            <div class="col-12">
                Payment
            </div>

            <div class="col-6">
                <div id="payment-element">
                    <!-- Elements will create form elements here -->
                </div>
                <button @click="confirmPayment">Subscribe</button>
                <div id="error-message">
                    {{ errorMsg }}
                </div>
            </div>
        </div>
</template>



<script>
    import Config from "../../configs";
    import {mixinBasics} from "../../mixins/basics";
    import {apiCall} from "../../helpers";
    import {loadStripe} from '@stripe/stripe-js/pure';

    const api = Config.restApi.endpoints.map;
    const priceId = 'price_1L8Xb9GvtsFoTQcJvLh81Mlf';


    export default {
        data () {
            return {
                loading: false,
                stripe: undefined,
                paymentOptions: {},
                elements: undefined,
                errorMsg: ''
            };
        },

        mixins: [
            mixinBasics
        ],

        computed: {

        },

        methods: {
            async createPaymentIntent() {
                console.log('createPaymentIntent:');
                let req = api.subscription.create;
                let res = await apiCall(req.url, req.method, {'price_id': priceId })
                return res.data;
            },

            async confirmPayment() {
                const {error} = await this.stripe.confirmPayment({
                    //`Elements` instance that was used to create the Payment Element
                    elements: this.elements,
                    confirmParams: {
                        return_url: `${window.location.href}/success`,
                    }
                });

                if (error) {
                    // This point will only be reached if there is an immediate error when
                    // confirming the payment. Show error to your customer (for example, payment
                    // details incomplete)
                    this.errorMsg = error.message;
                } else {
                    // Your customer will be redirected to your `return_url`. For some payment
                    // methods like iDEAL, your customer will be redirected to an intermediate
                    // site first to authorize the payment, then redirected to the `return_url`.
                }
            }
        },

        async created() {
            this.stripe = await loadStripe(Config.stripe.publishable_key);
        },

        async mounted() {
            this.paymentOptions = await this.createPaymentIntent();
            this.elements = this.stripe.elements(this.paymentOptions);
            const paymentElement = this.elements.create('payment');
            paymentElement.mount('#payment-element');
        }
    }
</script>
